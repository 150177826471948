import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  // ------------------ CTAs carousel
  const ctasCarousels = document.querySelectorAll('.ctas__carousel');

  if (0 < ctasCarousels.length) {
    ctasCarousels.forEach(function (ctasCarousel) {
      const swiperConfig = {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        freeMode: true,
        draggable: true,
        watchOverflow: {
          enabled: true,
        },
        keyboard: {
          enabled: true,
        },
        navigation: {
          nextEl: ctasCarousel.querySelector('.swiper-button-next'),
          prevEl: ctasCarousel.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: ctasCarousel.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
      };

      new Swiper(ctasCarousel.querySelector('.ctas-swiper'), swiperConfig);
    });
  }

  // ------------------ Training carousel
  const formationCarousels = document.querySelectorAll(
    '.formations__carrousel'
  );

  if (0 < formationCarousels.length) {
    formationCarousels.forEach(function (formationCarousel) {
      const buttonsDesktop = formationCarousel.querySelector(
        '.opcq__swiper__buttons--desktop'
      );

      const buttonsMobile = formationCarousel.querySelector(
        '.opcq__swiper__buttons--mobile'
      );

      const swiperConfig = {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 16,
        freeMode: false,
        draggable: true,
        watchOverflow: {
          enabled: false,
        },
        keyboard: {
          enabled: true,
        },
        breakpoints: {
          320: {
            navigation: {
              nextEl: buttonsMobile.querySelector('.swiper-button-next'),
              prevEl: buttonsMobile.querySelector('.swiper-button-prev'),
            },
          },
          1200: {
            navigation: {
              nextEl: buttonsDesktop.querySelector('.swiper-button-next'),
              prevEl: buttonsDesktop.querySelector('.swiper-button-prev'),
            },
          },
        },
        pagination: {
          el: formationCarousel.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
      };

      const newSwiper = () => {
        new Swiper(
          formationCarousel.querySelector('.formations-swiper'),
          swiperConfig
        );
      };

      newSwiper();

      const timeoutWindowResize = (myFunc) => {
        let timer;
        return (e) => {
          if (timer) clearTimeout(timer);
          timer = setTimeout(myFunc, 300, e);
        };
      };

      window.addEventListener(
        'resize',
        timeoutWindowResize(() => {
          newSwiper();
        })
      );
    });
  }
});
